import _ from 'lodash'
import { store } from './storeConfig/store'

// Simple selectors
export const getNewcomers = (state) => Object.values(state.auth.users).filter((user) => user.is_newcomer)
export const getTestWeeks = (state) => state.testWeeks
export const getTasks = () => store.getState().tasks.tasks
export const getFunctions = () => store.getState().functions.functions
export const getComponents = () => store.getState().components.components
export const getRoles = () => store.getState().roles.roles
export const getUsers = () => store.getState().auth.users
export const getUnits = () => store.getState().units.units
export const getPurchase = () => store.getState().purchases
export const getFilters = () => store.getState().filters
export const getQuizes = () => store.getState().quizes.quizes
export const getFeedbacks = () => store.getState().feedbacks.feedbacks
export const getQuizHealth = () => store.getState().quizHealth.quizHealth
export const getKpiCoefficientTypes = () => store.getState().kpi.kpiCoefficientTypes
export const getLoggedInUser = () => store.getState().auth.user
export const getMini360Reviews = () => store.getState().mini360Reviews.mini360Reviews
export const getOkrs = () => store.getState().okrs.okrs
export const getQuizTypes = () => store.getState().quizes.quizTypes
export const getApp = () => store.getState().app.app

export const adaptMini360Review = (mini360Review) => {
  // Creates representative data for table, change objects to string represantation
  const newMini360Review = _.cloneDeep(mini360Review)
  newMini360Review['unit'] = mini360Review.user ? mini360Review.user['unit'].map((unit) => unit.unit) : []
  newMini360Review['role'] = mini360Review.user ? mini360Review.user['role'].map((role) => role.role) : []
  return newMini360Review
}

export const adaptMini360Reviews = (state) => {
  const mini360Reviews = state.mini360Reviews.mini360Reviews

  // Creates representative data for table, change objects to string represantation
  let mini360ReviewList = Object.values(_.cloneDeep(mini360Reviews))
  mini360ReviewList = mini360ReviewList.map((mini360Review) => adaptMini360Review(mini360Review))
  return mini360ReviewList.reverse()
}

export const adaptSubmissionReportItem = (submissionReportItem) => {
  // Creates representative data for table, change objects to string represantation
  return _.cloneDeep(submissionReportItem)
}

export const adaptSubmissionReport = (state) => {
  const submissionReport = state.submissionReport.submissionReport
  // Creates representative data for table, change objects to string represantation
  let submissionReportList = Object.values(_.cloneDeep(submissionReport))
  submissionReportList = submissionReportList.map((submissionReportItem) => adaptSubmissionReportItem(submissionReportItem))
  return submissionReportList
}

export const getLastOnboardingAnswers = (state) => {
  const answersArr = Object.values(state.testAnswers.testAnswers).filter((el) => el.question.type === 'onboarding')
  const lastAnswer = answersArr[answersArr.length - 1]
  if (lastAnswer) {
    const indexOfLastAnswers = answersArr.findIndex((item) => item.attempt === lastAnswer.attempt)
    return answersArr.slice(indexOfLastAnswers)
  }
  return []
}

export const getLastEthicAnswers = (state) => {
  const answersArr = Object.values(state.testAnswers.testAnswers).filter((el) => el.question.type === 'code of ethics')
  const lastAnswer = answersArr[answersArr.length - 1]
  if (lastAnswer) {
    const indexOfLastAnswers = answersArr.findIndex((item) => item.attempt === lastAnswer.attempt)
    return answersArr.slice(indexOfLastAnswers)
  }
  return []
}

export const get10LastReview = (state) => {
  return adaptMini360Reviews(state)
    .filter((item) => item.avg_score)
    .reverse()
    .slice(-10)
}

export const getHasFinanceAccessSelector = (state) => {
  const loggedInUser = state.auth.user
  return loggedInUser?.setting_set?.find(({ key, value }) => key === 'finance' && value === 'true')
}
