// eslint-disable-next-line no-undef
import { API_DOMAIN, NODE_API_DOMAIN } from 'src/env'

const baseURL = API_DOMAIN || '/'
// eslint-disable-next-line no-undef
export const bffBaseUrl = NODE_API_DOMAIN || '/ops-node-bff/'

const token = () => `Bearer ${localStorage.getItem('token')}`

// export const refreshToken = newToken => token[0] = `Bearer ${newToken}`

export const fetchFetch = (path, opt) => {
  let safeUrl = '/' == path[0] ? path.substring(1) : path
  if (!opt.headers) opt.headers = {}
  if ('object' == typeof opt.body) {
    opt.headers['Content-Type'] = 'application/json'
  }
  if ('application/json' == opt.headers['Content-Type'] && 'object' == typeof opt.body) {
    opt.body = JSON.stringify(opt.body)
  }
  return fetch(`${baseURL}${safeUrl}`, opt)
}

export const authFetch = (path, init) => {
  let opt = {
    headers: {
      Authorization: token(),
    },
  }
  return fetchFetch(path, Object.assign(opt, init))
}

export const postJSON = (path, init) => {
  let opt = {
    method: 'POST',
    headers: {
      Authorization: token(),
      'Content-Type': 'application/json',
    },
  }

  return fetchFetch(path, Object.assign(opt, init))
}

export const patchJSON = (path, init) => {
  let opt = {
    method: 'PATCH',
    headers: {
      Authorization: token(),
      'Content-Type': 'application/json',
    },
  }

  return fetchFetch(path, Object.assign(opt, init))
}
