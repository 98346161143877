import React from 'react'
import { lazy } from 'react'
// eslint-disable-next-line no-restricted-imports
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

const Ripples = lazy(() => import('react-ripples'))

const RippleButton = ({ rippleColor, during, block, ...rest }) => (
  <Ripples color={rippleColor ? rippleColor : 'rgba(255, 255, 255, .5)'} during={during} className={`${block ? 'd-block' : ''}`}>
    <Button {...rest} />
  </Ripples>
)

RippleButton.propTypes = {
  ...Button.propTypes,
  rippleColor: PropTypes.string,
  during: PropTypes.number,
}

Button.Ripple = RippleButton
