import { ADD_CHECKLIST, GET_CHECKLISTS, SET_LAST_OPERATION } from '../../actions/checklists/actionTypes'

const initialState = {
  checklists: {},
  lastOperation: null,
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newChecklists = {}

  switch (actionType) {
    case GET_CHECKLISTS:
      payload.forEach((item) => (newChecklists[item.id] = item))
      return { ...state, checklists: newChecklists }
    case ADD_CHECKLIST:
      state[payload.id] = payload
      return { ...state, checklists: state.checklists }
    case SET_LAST_OPERATION:
      return { ...state, lastOperation: payload }
    default:
      return state
  }
}
