import { authFetch } from '../configs/fetchAPI'

const toUserOldFormat = (data) => {
  if (data.rights) {
    data.rights = data.rights.split(',')
  }
  data.role.forEach((role) => {
    role.main_performance_number = role.role_performancenumber?.[0]?.okr?.id
    role.second_performance_number = role.role_performancenumber?.[1]?.okr?.id
    role.third_performance_number = role.role_performancenumber?.[2]?.okr?.id
  })

  return data
}

const userRequest = {
  User: {
    select: [
      'id',
      'email',
      'is_superuser',
      'rights',
      'status',
      'full_name',
      'quiz_percent',
      'should_fill_checklist',
      'role_status',
      'is_hiring_manager',
      'is_barrazer',
      'termination_notice_period',
      'is_working_fulltime',
      'is_used_in_kpi',
      'critical_hit_task',
      'did_pass_security',
      'start_date',
      { offer: { select: ['id', 'critical_hit_task', 'probabation_period'] } },
      { unit: { select: ['id', 'unit', 'unit_mission', 'unit_lead_id'] } },
      { lead_unit: { select: ['id'] } },
      { profile: { select: ['id', 'avatar_img', 'profile_img'] } },
      {
        role: {
          select: [
            'id',
            'role',
            'target',
            { manager_hiring: { select: ['id'] } },
            { hrbp: { select: ['id'] } },
            {
              role_performancenumber: {
                select: ['id', 'position', { okr: { select: ['id'] } }],
                order: ['position'],
                limit: 3,
              },
            },
          ],
        },
      },
      { secondary_role: { select: ['id', 'role', 'target'] } },
      {
        simpleonetoone_set: {
          select: ['id', 'avg_score', 'created_at', 'completed'],
          order: ['created_at desc, completed desc'],
          limit: 1,
        },
      },
      { setting_set: { select: ['key', 'value'] } },
      {
        mini360_set: {
          select: ['id', 'avg_score', 'created_at'],
          order: ['created_at desc'],
          limit: 1,
        },
      },
    ],
  },
}

const fetchUser = () =>
  authFetch('/api/v2/query/user/', {
    method: 'POST',
    body: userRequest,
  })
    .then((response) => response.json())
    .then((json) => (json.rows?.[0]?.id ? toUserOldFormat(json.rows?.[0]) : {}))

export { fetchUser, toUserOldFormat, userRequest }
