import { ADD_HRTALK, GET_HRTALKS } from '../../actions/hrTalks/actionTypes'

const initialState = {
  hrTalks: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newHrTalks = {}

  switch (actionType) {
    case GET_HRTALKS:
      payload.forEach((item) => (newHrTalks[item.id] = item))
      return { ...state, hrTalks: newHrTalks }
    case ADD_HRTALK:
      state.hrTalks[payload.id] = payload
      return { ...state, hrTalks: { ...state.hrTalks } }
    default:
      return state
  }
}
