import { ADD_OKR, DELETE_OKR, GET_OKR, GET_OKRS, UPDATE_OKR } from '../../actions/okrs/actionTypes'

const initialState = {
  okrs: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const { payload, shouldOverwrite } = action
  const newOkrs = {}

  switch (actionType) {
    case GET_OKR:
      state.okrs[payload.id] = payload
      return { ...state, okrs: { ...state.okrs } }
    case GET_OKRS:
      payload.forEach((item) => (newOkrs[item.id] = item))
      return {
        ...state,
        okrs: shouldOverwrite ? newOkrs : { ...state.okrs, ...newOkrs },
      }
    case ADD_OKR:
      state.okrs[payload.id] = payload
      return { ...state, okrs: { ...state.okrs } }
    case UPDATE_OKR:
      state.okrs[payload.id] = payload
      return { ...state, okrs: { ...state.okrs } }
    case DELETE_OKR:
      delete state.okrs[payload]
      return { ...state, okrs: { ...state.okrs } }
    default:
      return state
  }
}
