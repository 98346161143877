import { GET_FEATURES } from '../../actions/features/actionTypes'

const initialState = {
  features: [],
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const features = {}

  switch (actionType) {
    case GET_FEATURES:
      payload.forEach((item) => (features[item.id] = item))
      return { ...state, features: features }
    default:
      return state
  }
}
