import $ from 'jquery'
import { ddmmyy } from 'src/helpers/date'
import {
  getFilters,
  getFunctions,
  getKpiCoefficientTypes,
  getMini360Reviews,
  getOkrs,
  getQuizes,
  getQuizTypes,
  getRoles,
  getTasks,
  getUnits,
  getUsers,
} from '../redux/selectors'
import { history } from '../history'

export const ROLE_STATUS_HIRED = 'hired'
export const ROLE_STATUS_NEWCOMER = 'newcomer'
export const ROLE_STATUS_DOCS_SIGNING = 'docs_signing'
export const ROLE_STATUS_DELETED = 'deleted'
export const ROLE_STATUS_REJECTED = 'rejected'
export const ROLE_STATUS_COLOR_HIRED = '#575fcf'
export const ROLE_STATUS_COLOR_NEWCOMER = '#90d1a7'
export const ROLE_STATUS_COLOR_DOCS_SIGNING = '#c6cd5d'
export const ROLE_STATUS_COLOR_DELETED = '#ea5455'
export const ROLE_STATUS_COLOR_REJECTED = '#ea5455'

export const isCEO = (user) => user?.id === 20

export const isCEOId = (userId) => userId === 20

export function monthToNum(date) {
  if (date === undefined || date === null || date.length !== 10) {
    return null
  }

  let yearNumber = date.substring(6, 10)
  let monthNumber = date.substring(3, 5)
  let dayNumber = date.substring(0, 2)

  // 29/08/2004 => 20040829
  return yearNumber * 10000 + monthNumber * 100 + dayNumber
}

export function datetimeToNum(date) {
  if (date === undefined || date === null || date.length !== 16) {
    return null
  }

  let yearNumber = date.substring(6, 10)
  let monthNumber = date.substring(3, 5)
  let dayNumber = date.substring(0, 2)
  let hhNumber = date.substring(11, 13)
  let mmNumber = date.substring(14, 16)

  // 29/08/2004 12:34 => 200408291234
  return yearNumber * 1000000 + monthNumber * 10000 + dayNumber + 100 * hhNumber + mmNumber
}

export function dateComparator(date1, date2) {
  if (date1 === null && date2 === null) {
    return 0
  }
  if (date1 === null) {
    return -1
  }
  if (date2 === null) {
    return 1
  }
  let date1Number = Date.parse(date1)
  let date2Number = Date.parse(date2)

  return date1Number - date2Number
}

export function datetimeComparator(date1, date2) {
  let date1Number = datetimeToNum(date1)
  let date2Number = datetimeToNum(date2)

  if (date1Number === null && date2Number === null) {
    return 0
  }
  if (date1Number === null) {
    return -1
  }
  if (date2Number === null) {
    return 1
  }

  return date1Number - date2Number
}

function toNumber(value, radix = 2) {
  return Number(value.toFixed(radix))
}

export function numberComparator(n1, n2) {
  return toNumber(n1, 0) > toNumber(n2, 0) ? 1 : -1
}

export function simpleNumberComparator(n1, n2) {
  return Number(n1) > Number(n2) ? 1 : -1
}

function setCookie(cookieName, cookieValue, expiredMinutes) {
  const d = new Date()
  d.setTime(d.getTime() + expiredMinutes * 60 * 1000)
  const expires = `expires=${d.toUTCString()}`
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`
}

export const deleteAllAuthCookies = () => {
  const cookies = document.cookie.split(';')
  const authCookies = ['sessionid', ' csrftoken']
  for (let i = 0; i < cookies.length; i++) {
    const cookie = authCookies[i]
    setCookie(cookie, '', 0)
  }
}

export const getParams = (arr, field) => {
  if (arr) {
    return arr.map((row) => {
      if (Boolean(row) && typeof row === 'object') {
        return row[field]
      }
      return row
    })
  }
  return []
}

export const getParamsWithIDInTitle = (arr, field, IDField) => {
  if (arr) {
    return arr.map((row) => `${row[field]} (id=${row[IDField]})`)
  }
  return []
}

export const extractIDFromTitle = (title) => {
  let count = 0
  let idx = title.length - 1
  let id = ''
  while (count < 2 && idx >= 0) {
    if (!isNaN(title[idx])) {
      id = title[idx] + id
    } else if (['(', ')', '='].includes(title[idx])) {
      count += 1
    }
    idx -= 1
  }
  return Number(id)
}

// Make urls in question title clickable in surveyjs form
export const processUrlTextClickable = (text) => {
  const words = text.split(' ')
  words.forEach((word, idx) => {
    if (word.trim().startsWith('http')) {
      words[idx] = `[LINK] (${word.trim()})`
    }
  })
  return words.join(' ')
}

export const getPaymentPeriod = (date) => {
  let res = date.format('YYYY-MM')
  return `${res} ${parseInt(date.format('DD')) > 15 ? 'H2' : 'H1'}`
}

// Get period of given date
export const getPeriod = (date) => {
  const startOfMonth = date.endOf('isoWeek').clone().startOf('month')
  let weekNumber = 0
  for (let i = 0; i < 5; i += 1) {
    weekNumber = i + 1
    let weekStart = startOfMonth.clone().startOf('isoWeek')
    let weekEnd = startOfMonth.clone().endOf('isoWeek')
    let flag = false

    while (weekEnd.isSameOrAfter(weekStart)) {
      if (date.clone().format('DD/MM/YYYY') === weekStart.format('DD/MM/YYYY')) {
        flag = true
        break
      }

      weekStart.add(1, 'days')
    }
    if (flag) {
      break
    }
    startOfMonth.add(7, 'days')
  }

  const month = date.month() + 1
  const year = date.year()
  return `${year}-${month} ${weekNumber}`
}

const moscowTime = () => {
  let t = new Date()
  return new Date(t.getUTCFullYear(), t.getUTCMonth(), t.getUTCDate(), t.getUTCHours() + 3)
}

export const weekMonday = (date) => {
  let d = date ? date : moscowTime()
  let day = d.getDay()
  let dayOfTheMonth = d.getDate()
  let from = new Date(d.setDate(dayOfTheMonth - day + (day === 0 ? -6 : 1)))
  from.setHours(0, 0, 0, 0)
  return from
}

const leadZero = (num) => (num < 10 ? `0${num}` : num)

export const formatDate = (someDate, iso = false) => {
  let dd = someDate.getDate()
  let mm = someDate.getMonth() + 1
  let y = someDate.getFullYear()

  return iso ? [y, leadZero(mm), leadZero(dd)].join('-') : [leadZero(dd), leadZero(mm), y].join('/')
}

export const weekMondayStr = (date, delimiter = '-') => formatDate(weekMonday(date), '-' === delimiter)

export class DateFieldsComparator {
  static dateFilterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      let cellDate
      try {
        let dateParts = cellValue.split('/')
        let year = Number(dateParts[2])
        let month = Number(dateParts[1]) - 1
        let day = Number(dateParts[0])
        cellDate = new Date(year, month, day)
      } catch (e) {
        return -1
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1
      }
      return 0
    },
  }

  static datetimeFilterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      let cellDate
      try {
        let datetimeParts = cellValue.split(' ')
        let dateParts = datetimeParts[0].split('/')
        let year = Number(dateParts[2])
        let month = Number(dateParts[1]) - 1
        let day = Number(dateParts[0])
        cellDate = new Date(year, month, day)
      } catch (e) {
        return -1
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1
      }
      return 0
    },
  }
}

const setDefaultFilters = (loggedInUser, setUnitFilter, setRoleFilter, setUserFilter) => {
  const filters = getFilters()
  filters.unitFilter.unit === null && loggedInUser && loggedInUser.unit.length && setUnitFilter(loggedInUser.unit[0])
  filters.roleFilter.role === null && loggedInUser && loggedInUser.role.length && setRoleFilter(loggedInUser.role[0])
  filters.userFilter === null && loggedInUser && setUserFilter(loggedInUser.email)
}

// Function to fetch initial data and set initial filters, configs, settings
export const fetchInitialDataAndSetDefaultFilters = (setUnitFilter, setRoleFilter, setUserFilter, fetchApp, fetchComponents, loggedInUser) =>
  Promise.all([fetchApp(), fetchComponents()]).then(() => setDefaultFilters(loggedInUser, setUnitFilter, setRoleFilter, setUserFilter))

export const getUnitOfRole = (role) => {
  const units = Object.values(getUnits())
  for (const unit of units) {
    const rolesInUnit = getParams(unit.role, 'role')
    if (rolesInUnit.includes(role)) {
      return unit.unit
    }
  }
  return -1
}

export const getUnitByRoleID = (roleId) => {
  const units = Object.values(getUnits())
  for (const unit of units) {
    const roleIdsInUnit = getParams(unit.role, 'id')
    if (roleIdsInUnit.includes(roleId)) {
      return unit.unit
    }
  }
  return -1
}

export const getQuestionByID = (questionID, questions) => {
  for (let question of questions) {
    if (question.id === parseInt(questionID)) {
      return question
    }
  }
  return null
}

// Disable scrolling on number inputs (HTML input element)
export const disableInputNumberScrollingHTML = () => {
  $(document)
    // eslint-disable-next-line func-names
    .on('wheel', 'input[type=number]', function () {
      // eslint-disable-next-line no-invalid-this
      $(this).blur()
    })
}

export const getRoleByID = (id) => {
  const roles = Object.values(getRoles())
  for (const role of roles) {
    if (role.id === parseInt(id)) {
      return role
    }
  }
  return null
}

export const getRoleByRoleTitle = (roleTitle) => {
  const roles = Object.values(getRoles())
  for (const role of roles) {
    if (role.role === roleTitle) {
      return role
    }
  }
  return null
}

export const getUnitByTitle = (unitTitle) => {
  const units = Object.values(getUnits())
  for (const unit of units) {
    if (unit.unit === unitTitle) {
      return unit
    }
  }
  return null
}

export const getUnitById = (id) => {
  const units = Object.values(getUnits())
  for (const unit of units) {
    if (unit.id === id) {
      return unit
    }
  }
  return null
}

export const isNumber = (value) => Boolean(value) && 'Number' === value.constructor.name

export const isEqualArrays = (array1, array2) => array1.length && array2.length && JSON.stringify(array1.sort()) === JSON.stringify(array2.sort())

export const roundNumber = (params, radix = 0) => {
  if (!params.value) return params.value
  if (isNumber(params.value)) {
    return params.value.toFixed(radix)
  } else if ('Object' === params.value.constructor.name) {
    if (isNumber(params.value.value)) return params.value.value.toFixed(radix)
  }
  return params.value
}

export const roundNumberPositive = (params, radix = 0) => {
  if (!params.value) return params.value
  if (isNumber(params.value)) {
    return Math.max(0, params.value.toFixed(radix))
  } else if ('Object' === params.value.constructor.name) {
    if (isNumber(params.value.value)) return Math.max(0, params.value.value.toFixed(radix))
  }
  return params.value
}

export const roundFloatNumber = (params) => roundNumber(params, 1)

export const getUnitPath = (unitId) => {
  const unit = getUnitById(unitId)
  // eslint-disable-next-line no-nested-ternary
  return unit && unit.parent ? getUnitPath(unit?.parent.id).concat([unit.unit]) : unit ? [unit.unit] : []
}

export const getUserByEmail = (email) => {
  const users = Object.values(getUsers())
  for (const user of users) {
    if (user.email === email) {
      return user
    }
  }
  return null
}

export const getQuizByTitle = (title) => {
  const quizes = Object.values(getQuizes())
  for (const quiz of quizes) {
    if (quiz.title === title) {
      return quiz
    }
  }
  return null
}

export const getQuizById = (id) => {
  const quizes = Object.values(getQuizes())
  for (const quiz of quizes) {
    if (quiz.id === id) {
      return quiz
    }
  }
  return null
}

export const getOkrById = (id) => {
  const okrs = getOkrs()
  if (id in okrs) return okrs[id]
  return null
}

export const getUserById = (id) => {
  const users = getUsers()
  if (id in users) return users[id]
  return null
}

export const getQuizTypeByType = (type) => {
  const quizTypes = Object.values(getQuizTypes())
  for (const item of quizTypes) {
    if (item.type === type) return item
  }
  return null
}

export const getQuizTypeById = (id) => {
  const quizTypes = getQuizTypes()
  if (id in quizTypes) return quizTypes[id]
  return null
}

export const getKpiCoefficientTypeById = (id) => {
  const kpiCoefficientTypes = getKpiCoefficientTypes()
  if (kpiCoefficientTypes[id]) return kpiCoefficientTypes[id]
  return null
}

export const isUserFilledMini360ForGivenWeek = (user, date) => {
  let reviews = Object.values(getMini360Reviews())
  const period = getPeriod(date)
  reviews = reviews.filter((item) => item.user && item.user.email === user.email && item.period === period).sort((a, b) => a.localeCompare(b))
  return reviews.length > 0 && reviews[0].period === period && reviews[0].already_filled_form
}

export const getRoleRateInfo = (role) => {
  const colors = {
    0: '#000',
    1: '#8B0000',
    2: '#FF0000',
    3: '#FFA500',
    4: '#3CB371',
    5: '#008000',
  }
  const result = {
    rate: 0,
    rate_checklist: 0,
    rate_barrazer: 0,
    rate_info: '',
    rate_checklist_info: '',
    rate_barrazer_info: '',
    onboarding_barazer_rate: 0,
    onboarding_user_rate: 0,
    onboarding_barazer_rate_info: '',
    onboarding_user_rate_info: '',
    rate_okr_bar: 0,
    rate_okr_bar_info: '',
  }

  if (role && role.ratings) {
    const itemFeedbackInfo = (feedback) =>
      `<span style="color: ${colors[feedback.rate]}">- ${feedback.rate}. ${ddmmyy(feedback.created_at).format('DD.MM.YYYY')}. ${feedback.author}. ${
        feedback.feedback
      }</span>`

    if (role.ratings.rate_avg) result.rate = role.ratings.rate_avg
    if (role.ratings.rate_checklist_avg) result.rate_checklist = role.ratings.rate_checklist_avg
    if (role.ratings.rate_barrazer_avg) result.rate_barrazer = role.ratings.rate_barrazer_avg
    if (role.ratings.onboarding_barazer_rate_avg) result.onboarding_barazer_rate = role.ratings.onboarding_barazer_rate_avg
    if (role.ratings.onboarding_user_rate_avg) result.onboarding_user_rate = role.ratings.onboarding_user_rate_avg
    if (role.ratings.rate_okr_bar_avg) result.rate_okr_bar = role.ratings.rate_okr_bar_avg

    if (role.ratings.rate && role.ratings.rate.length) {
      result.rate_info = role.ratings.rate.map((item) => itemFeedbackInfo(item)).join('<br />')
    }
    if (role.ratings.rate_checklist && role.ratings.rate_checklist.length) {
      result.rate_checklist_info = role.ratings.rate_checklist.map((item) => itemFeedbackInfo(item)).join('<br />')
    }
    if (role.ratings.rate_barrazer && role.ratings.rate_barrazer.length) {
      result.rate_barrazer_info = role.ratings.rate_barrazer.map((item) => itemFeedbackInfo(item)).join('<br />')
    }
    if (role.ratings.onboarding_barazer_rate && role.ratings.onboarding_barazer_rate.length) {
      result.onboarding_barazer_rate_info = role.ratings.onboarding_barazer_rate.map((item) => itemFeedbackInfo(item)).join('<br />')
    }
    if (role.ratings.onboarding_user_rate && role.ratings.onboarding_user_rate.length) {
      result.onboarding_user_rate_info = role.ratings.onboarding_user_rate.map((item) => itemFeedbackInfo(item)).join('<br />')
    }
    if (role.ratings.rate_okr_bar && role.ratings.rate_okr_bar.length) {
      result.rate_okr_bar_info = role.ratings.rate_okr_bar.map((item) => itemFeedbackInfo(item)).join('<br />')
    }
  }

  result.rate_color = colors[result.rate]
  result.rate_checklist_color = colors[result.rate_checklist]
  result.rate_barrazer_color = colors[result.rate_barrazer]
  result.onboarding_barazer_rate_color = colors[result.onboarding_barazer_rate]
  result.onboarding_user_rate_color = colors[result.onboarding_user_rate]
  result.rate_okr_bar_color = colors[result.rate_okr_bar]

  return result
}

export const getFunctionsByRoleID = (roleId) => {
  const functions = Object.values(getFunctions()).filter((item) => item.function !== 'Non routine function')
  const result = []
  functions
    .sort((a, b) => a.priority - b.priority)
    .forEach((func) => {
      func.role.forEach((role) => {
        if (role === roleId) {
          result.push(func)
        }
      })
    })
  return result
}

export const getTasksByFunctionID = (functionID) => {
  const tasks = Object.values(getTasks()).filter((item) => item.function !== 'Non routine task')
  return tasks.filter((task) => (task.function || []).map((f) => f.id).includes(functionID)).sort((a, b) => a.order - b.order)
}

export const getUniqueFunctionsByUnit = (unit) => {
  const data = Object.values(getFunctions())
    .filter((item) => item.function_long !== '' && item.function_long !== 'Non routine function' && item.unit.map((item) => item.unit).includes(unit))
    .map((item) => item.function_long)

  return data.filter((item, pos) => data.indexOf(item) === pos).sort((a, b) => `${a}`.localeCompare(b))
}

export const getOkrsByUnit = (unit) => {
  const data = Object.values(getOkrs())
    .filter((item) => item.unit === unit)
    .map((item) => item)
    .sort((a, b) => `${a.title}`.localeCompare(b.title))

  data.push(
    ...Object.values(getOkrs())
      .filter((item) => item.unit !== unit)
      .map((item) => item)
      .sort((a, b) => `${a.title}`.localeCompare(b.title)),
  )

  return data
}

export const getUnitsByRole = (role) =>
  Object.values(getUnits())
    .filter((item) => item.role.map((item) => item.role).includes(role))
    .map((item) => item.unit)

export const getRolesOfUnit = (unit) => {
  const units = getUnits()
  if (units[unit].role) return units[unit].role
  return []
}

export const updateUrlParams = (paramTitle, paramValue) => {
  const params = new URLSearchParams(history.location.search)
  params.set(paramTitle, paramValue)
  history.replace({ search: params.toString() })
}

export const getShortName = (full_name) => {
  const prepareData = full_name ? full_name.split(' ', 2) : [null, null]
  return (prepareData[1] ? prepareData[1].substring(0, 3) : '') + (prepareData[0] ? prepareData[0][0] : '')
}

// TODO: Move to role observer
export const hasRightsToEditRoleData = (user, role) => {
  const userId = user && user.id
  const userSettings = (user.setting_set ?? []).reduce(
    (acc, setting) => ({
      ...acc,
      [setting.key]: setting.value,
    }),
    {},
  )
  return (
    userId == 20 ||
    'true' == userSettings.rights_edit_profiles ||
    userId == role?.hrbp ||
    userId == role?.hrbp?.id ||
    userId == role?.manager_hiring ||
    userId == role?.manager_hiring?.id ||
    user.is_hiring_manager ||
    user?.lead_unit?.some((unit) => role?.unit_set?.map((e) => e?.id).includes(unit?.id))
  )
}

export const statusToValue = (status) => {
  let value = 0
  if (status == 'Champion') value = 4.5
  else if (status == 'Good Fit') value = 3.5
  else if (status == 'Medium') value = 2.5
  else if (status == 'Below Average') value = 1.5
  else if (status == 'Danger') value = 1
  return value
}

export const capitalizeString = (str) => {
  if (!str) return null
  let splitStr = str.toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

export const defineUserType = (user) => {
  if (isCEO(user)) return 'ceo'
  if (user.is_barrazer) return 'br'
  if (user.is_hiring_manager) return 'hm'
  if (user.rights.includes('hr')) return 'hr'
  if (user.rights.includes('unit lead')) return 'head'
  return 'user'
}

export const getRoleStatusColor = (role_status) => {
  let color = ROLE_STATUS_COLOR_HIRED
  if (role_status === ROLE_STATUS_NEWCOMER) color = ROLE_STATUS_COLOR_NEWCOMER
  else if (role_status === ROLE_STATUS_DOCS_SIGNING) color = ROLE_STATUS_COLOR_DOCS_SIGNING
  else if (role_status === ROLE_STATUS_DELETED) color = ROLE_STATUS_COLOR_DELETED
  else if (role_status === ROLE_STATUS_REJECTED) color = ROLE_STATUS_COLOR_REJECTED
  return color
}

export const hasRightsToApproveChecklistRevisions = (user) => {
  const userId = user && user.id
  const userSettings = (user.setting_set ?? []).reduce(
    (acc, setting) => ({
      ...acc,
      [setting.key]: setting.value,
    }),
    {},
  )
  return userId == 20 || userSettings.rights_approve_revisions == 'true'
}

export const getFormattedDate = (date) => {
  const parsedDate = new Date(date)
  return `${`0${parsedDate.getDate()}`.slice(-2)}/${`0${parsedDate.getMonth() + 1}`.slice(-2)}/${parsedDate.getFullYear()}`
}

export const getDateFromISO = (ISOdate) => ISOdate?.split('T')?.[0]

const getDayMonthFormat = (date) => {
  let dd = String(date.getDate()).padStart(2, '0')
  let mm = String(date.getMonth() + 1).padStart(2, '0')
  return `${dd}.${mm}`
}

export const periodToDates = (period) => {
  let today = new Date()
  let tomorrow = new Date()
  tomorrow.setDate(today.getDate() + 1)
  let yesterday = new Date()
  yesterday.setDate(today.getDate() - 1)

  switch (period) {
    case 'today':
      return `${getDayMonthFormat(today)} - ${getDayMonthFormat(today)}`
    case 'yesterday':
      return `${getDayMonthFormat(yesterday)} - ${getDayMonthFormat(yesterday)}`
    case 'tomorrow':
      return `${getDayMonthFormat(tomorrow)} - ${getDayMonthFormat(tomorrow)}`
    default:
      return ''
  }
}

export const slashToHyphen = (word) => word.replaceAll('/', '-')

// eslint-disable-next-line require-unicode-regexp
export const minutesToHours = (value) => (value ? `${(Number(value) / 60).toFixed(1).replace(/[.,]0$/, '')}` : '0')

export const periodHasStartAndEndDates = (period) => period.length === 2

export function groupBy(list, keyGetter) {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

export function sleep(time, value) {
  return new Promise((resolve) => setTimeout(() => resolve(value), time))
}

export function waitForUnitsReduxKey() {
  const CHECK_INTERVAL = 200

  return new Promise((resolve) => {
    const checker = () => {
      const units = getUnits()
      if (Object.entries(units).length) {
        resolve()
        return
      }
      setTimeout(checker, CHECK_INTERVAL)
    }
    checker()
  })
}
