import { GET_SUBMISSION_REPORT } from '../../actions/mini360Reviews/actionTypes'

const initialState = {
  submissionReport: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  switch (actionType) {
    case GET_SUBMISSION_REPORT:
      return { ...state, submissionReport: payload }
    default:
      return state
  }
}
