import { ADD_QUIZANSWERS, GET_QUIZANSWERS } from '../../actions/quizAnswers/actionTypes'

const initialState = {
  quizAnswers: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newQuizAnswers = {}
  switch (actionType) {
    case GET_QUIZANSWERS:
      payload.forEach((item) => (newQuizAnswers[item.id] = item))
      return { ...state, quizAnswers: newQuizAnswers }
    case ADD_QUIZANSWERS:
      state.quizAnswers[payload.id] = payload
      return { ...state, quizAnswers: { ...state.quizAnswers } }
    default:
      return state
  }
}
