import {
  ADD_KPI,
  ADD_KPI_COEFFICIENT_VALUE,
  GET_KPI,
  GET_KPI_CALCULATION_FUNCTIONS,
  GET_KPI_COEFFICIENT_TYPES,
  GET_KPI_COEFFICIENT_VALUES,
  UPDATE_KPI,
  UPDATE_KPI_COEFFICIENT_VALUE,
} from '../../actions/kpi/actionTypes'

const initialState = {
  kpiCoefficientValues: {},
  kpiCalculationFunctions: {},
  kpi: {},
  kpiCoefficientTypes: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newKpiCoefficientValues = {}
  const newKpiCalculationFunctions = {}
  const newKpiCoefficientTypes = {}
  const newKpi = {}
  switch (actionType) {
    case ADD_KPI:
      payload.forEach((item) => (newKpi[item.id] = item))
      return { ...state, kpi: { ...state.kpi, ...newKpi } }
    case UPDATE_KPI:
      payload.forEach((item) => (newKpi[item.id] = item))
      return { ...state, kpi: payload }
    case GET_KPI:
      payload.forEach((item) => (newKpi[item.id] = item))
      return { ...state, kpi: payload }
    case ADD_KPI_COEFFICIENT_VALUE:
      state['kpiCoefficientValues'][payload.id] = payload
      return { ...state, kpiCoefficientValues: state.kpiCoefficientValues }
    case UPDATE_KPI_COEFFICIENT_VALUE:
      state['kpiCoefficientValues'][payload.id] = payload
      return { ...state, kpiCoefficientValues: state.kpiCoefficientValues }
    case GET_KPI_COEFFICIENT_VALUES:
      payload.forEach((item) => (newKpiCoefficientValues[item.id] = item))
      return { ...state, kpiCoefficientValues: newKpiCoefficientValues }
    case GET_KPI_COEFFICIENT_TYPES:
      payload.forEach((item) => (newKpiCoefficientTypes[item.id] = item))
      return { ...state, kpiCoefficientTypes: newKpiCoefficientTypes }
    case GET_KPI_CALCULATION_FUNCTIONS:
      payload.forEach((item) => (newKpiCalculationFunctions[item.id] = item))
      return { ...state, kpiCalculationFunctions: newKpiCalculationFunctions }
    default:
      return state
  }
}
