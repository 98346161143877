import axios from 'axios'
import { API_DOMAIN } from 'src/env'

// eslint-disable-next-line no-undef
const baseURL = API_DOMAIN
const urlsWhichDoesNotRequireAuthToken = ['/api/auth/google/', '/api/auth/login/']

// eslint-disable-next-line no-empty-function
export const tokenConfig = () => {}

export const axiosConfig = axios.create({
  baseURL,
  headers: { 'Content-Type': 'application/json' },
})

axiosConfig.interceptors.request.use((config) => {
  if (!urlsWhichDoesNotRequireAuthToken.includes(config.url) && localStorage.getItem('token')) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  }
  return config
})

axiosConfig.interceptors.response.use(
  (config) => config,
  async (error) => {
    if (error.message == 'cancelled') {
      return { data: [] }
    }
    const refreshToken = localStorage.getItem('refresh_token')
    if (
      error.response.status === 401 &&
      error.config &&
      // eslint-disable-next-line no-underscore-dangle
      !error.config._isRetry &&
      refreshToken
    ) {
      const originalRequest = error.config
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._isRetry = true
      try {
        const response = await axios.post(`${baseURL}api/auth/token/refresh/`, {
          refresh: refreshToken,
        })
        localStorage.setItem('token', response.data.access)
        return axiosConfig.request(originalRequest)
      } catch (e) {
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('token')
      }
    }
    throw error
  },
)
