import { GET_SUGGESTION_USERS, GET_USER, GET_USERS, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, SIGNUP_FAIL } from 'src/redux/actions/auth/actionTypes'

const initialState = {
  user: {},
  users: {},
  isAuthenticated: false,
  token: localStorage.getItem('token'),
  initialUser: localStorage.getItem('initialUser') && JSON.parse(localStorage.getItem('initialUser')),
}

export default (state = initialState, action) => {
  const actionType = action.type
  const { payload } = action
  const newUsers = {}

  switch (actionType) {
    case GET_USER:
      return {
        ...state,
        isAuthenticated: true,
        user: payload,
      }
    case GET_USERS:
      payload.forEach((item) => (newUsers[item.id] = item))
      return {
        ...state,
        users: newUsers,
      }
    case GET_SUGGESTION_USERS:
      payload.forEach((item) => (newUsers[item.id] = item))
      return {
        ...state,
        users: newUsers,
      }
    case LOGIN_SUCCESS: {
      localStorage.setItem('token', payload.access_token)
      localStorage.setItem('refresh_token', payload.refresh_token)
      localStorage.setItem('initialUser', JSON.stringify(payload.user || null))
      return {
        ...state,
        isAuthenticated: true,
        token: payload.access_token,
        user: payload.user,
      }
    }
    case SIGNUP_FAIL:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      return {
        ...state,
        isAuthenticated: false,
        token: null,
      }
    default: {
      return state
    }
  }
}
