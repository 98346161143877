export const SET_ROLEFILTER = 'SET_ROLEFILTER'
export const SET_MULTIROLEFILTER = 'SET_MULTIROLEFILTER'
export const SET_UNITFILTER = 'SET_UNITFILTER'
export const SET_USERFILTER = 'SET_USERFILTER'
export const SET_DATEFILTER = 'SET_DATEFILTER'
export const SET_OFFERFILTER = 'SET_OFFERFILTER'
export const SET_ORGSTRUCTURE_TABLE_FILTER = 'SET_ORGSTRUCTURE_TABLE_FILTER'
export const SET_ONBOARDING_TABLE_FILTER = 'SET_ONBOARDING_TABLE_FILTER'
export const SET_QUIZTYPE_FILTER = 'SET_QUIZTYPE_FILTER'
export const SET_QUIZSTATUS_FILTER = 'SET_QUIZSTATUS_FILTER'
export const SET_OKR_STATUS_FILTER = 'SET_OKR_STATUS_FILTER'
export const SET_MULTIUNIT_FILTER = 'SET_MULTIUNIT_FILTER'
export const SET_PERIOD_FILTER = 'SET_PERIOD_FILTER'
export const SET_QUARTERLY_FILTER = 'SET_QUARTERLY_FILTER'
