import { GET_APP, UPDATE_APP } from '../../actions/app/actionTypes'

const initialState = {
  app: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload

  switch (actionType) {
    case GET_APP:
      return { ...state, app: payload[0] }
    case UPDATE_APP:
      return { ...state, app: payload }
    default:
      return state
  }
}
