const initialState = { profilePage: {} }

export default (state = initialState, action) => {
  if ('setProfilePage' == action.type) {
    return action.action
  }
  if ('setProfilePageTimeStamp' == action.type) {
    return { ...state, timeStamp: performance.now() }
  }
  return { ...state }
}
