import { GET_ONBOARDING_ARTICLES } from '../../actions/onboardingArticles/actionTypes'

const initialState = {
  onboardingArticles: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload

  switch (actionType) {
    case GET_ONBOARDING_ARTICLES:
      const newOnboadringArticles = {}
      payload.forEach((item) => (newOnboadringArticles[item.id] = item))
      return { ...state, onboardingArticles: newOnboadringArticles }
    default:
      return state
  }
}
