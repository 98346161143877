import { ADD_QUIZ, DELETE_QUIZ, GET_QUIZ, GET_QUIZ_TYPES, GET_QUIZES, GET_QUIZZES_INFO, UPDATE_QUIZ } from '../../actions/quizes/actionTypes'

const initialState = {
  quizes: {},
  quizTypes: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newQuizes = {}
  const newQuizTypes = {}
  switch (actionType) {
    case GET_QUIZES:
      payload.forEach((item) => (newQuizes[item.id] = item))
      return { ...state, quizes: newQuizes }
    case GET_QUIZZES_INFO:
      payload.forEach((item) => (newQuizes[item.id] = item))
      return { ...state, quizes: newQuizes }
    case GET_QUIZ:
      state.quizes[payload.id] = payload
      return { ...state, quizes: { ...state.quizes } }
    case ADD_QUIZ:
      state.quizes[payload.id] = payload
      return { ...state, quizes: { ...state.quizes } }
    case DELETE_QUIZ:
      state.quizes[payload.id] = payload
      return { ...state, quizes: { ...state.quizes } }
    case UPDATE_QUIZ:
      delete state.quizes[payload]
      return { ...state, quizes: { ...state.quizes } }
    case GET_QUIZ_TYPES:
      payload.forEach((item) => (newQuizTypes[item.id] = item))
      return { ...state, quizTypes: newQuizTypes }
    default:
      return state
  }
}
