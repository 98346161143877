import { LOGIN_FAIL } from '../auth/actionTypes'
import { axiosConfig as axios, tokenConfig } from '../../../configs/axiosConfig'
import { GET_COMPONENTS } from './actionTypes'

export const fetchComponents =
  (customFilterQuery = null) =>
  async (dispatch, getState) => {
    try {
      const filterQuery = customFilterQuery ? customFilterQuery : ''
      const response = await axios.get(`/api/v2/components/?${filterQuery}`, tokenConfig(getState))

      dispatch({
        type: GET_COMPONENTS,
        payload: response.data,
      })
    } catch (err) {
      if (err.response.status === 403 && err.response.data && err.response.data.detail === 'Authentication credentials were not provided.') {
        dispatch({
          type: LOGIN_FAIL,
        })
      }
      console.log(err)
    }
  }
