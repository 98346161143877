import { ADD_QUIZ_QUESTION, DELETE_QUIZ_QUESTION, GET_QUIZ_QUESTIONS, UPDATE_QUIZ_QUESTION } from '../../actions/quizQuestions/actionTypes'

const initialState = {
  quizQuestions: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newQuizQuestions = {}
  switch (actionType) {
    case GET_QUIZ_QUESTIONS:
      payload.forEach((item) => (newQuizQuestions[item.id] = item))
      return { ...state, quizQuestions: newQuizQuestions }
    case ADD_QUIZ_QUESTION:
      state.quizQuestions[payload.id] = payload
      return { ...state, quizQuestions: { ...state.quizQuestions } }
    case DELETE_QUIZ_QUESTION:
      state.quizQuestions[payload.id] = payload
      return { ...state, quizQuestions: { ...state.quizQuestions } }
    case UPDATE_QUIZ_QUESTION:
      delete state.quizQuestions[payload]
      return { ...state, quizQuestions: { ...state.quizQuestions } }
    default:
      return state
  }
}
