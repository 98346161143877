import { GET_QUIZ_HEALTH } from '../../actions/quizes/actionTypes'

const initialState = {
  quizHealth: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const quizHealth = {}
  switch (actionType) {
    case GET_QUIZ_HEALTH:
      payload.forEach((item) => (quizHealth[item.id] = item))
      return { ...state, quizHealth: quizHealth }
    default:
      return state
  }
}
