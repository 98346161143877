import { ADD_MINI360REVIEW, GET_MINI360REVIEW, GET_MINI360REVIEWS } from '../../actions/mini360Reviews/actionTypes'

const initialState = {
  mini360Reviews: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newMini360Reviews = {}
  switch (actionType) {
    case GET_MINI360REVIEWS:
      payload.forEach((item) => (newMini360Reviews[item.id] = item))
      return { ...state, mini360Reviews: newMini360Reviews }
    case ADD_MINI360REVIEW:
      state.mini360Reviews[payload.id] = payload
      return { ...state, mini360Reviews: { ...state.mini360Reviews } }
    case GET_MINI360REVIEW:
      if (payload.length > 0) state.mini360Reviews[payload.id] = payload
      return { ...state, mini360Reviews: { ...state.mini360Reviews } }
    default:
      return state
  }
}
