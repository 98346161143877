import {
  SET_DATEFILTER,
  SET_MULTIROLEFILTER,
  SET_MULTIUNIT_FILTER,
  SET_OFFERFILTER,
  SET_OKR_STATUS_FILTER,
  SET_ONBOARDING_TABLE_FILTER,
  SET_ORGSTRUCTURE_TABLE_FILTER,
  SET_PERIOD_FILTER,
  SET_QUARTERLY_FILTER,
  SET_QUIZSTATUS_FILTER,
  SET_QUIZTYPE_FILTER,
  SET_ROLEFILTER,
  SET_UNITFILTER,
  SET_USERFILTER,
} from './actionTypes'

export const setUnitFilter = (value) => async (dispatch) => {
  dispatch({
    type: SET_UNITFILTER,
    payload: value,
  })
  return Promise.resolve(value)
}

export const setRoleFilter = (value) => async (dispatch) => {
  dispatch({
    type: SET_ROLEFILTER,
    payload: value,
  })
  return Promise.resolve(value)
}

export const setMultiRoleFilter = (value) => async (dispatch) => {
  dispatch({
    type: SET_MULTIROLEFILTER,
    payload: value,
  })
  return Promise.resolve(value)
}

export const setMultiUnitFilter = (value) => async (dispatch) => {
  dispatch({
    type: SET_MULTIUNIT_FILTER,
    payload: value,
  })
  return Promise.resolve(value)
}

export const setUserFilter = (value) => async (dispatch) => {
  dispatch({
    type: SET_USERFILTER,
    payload: value,
  })
  return Promise.resolve(value)
}

export const setDateFilter = (value) => async (dispatch) => {
  dispatch({
    type: SET_DATEFILTER,
    payload: value,
  })
  return Promise.resolve(value)
}

export const setOfferFilter = (value) => async (dispatch) => {
  dispatch({
    type: SET_OFFERFILTER,
    payload: value,
  })
  return Promise.resolve(value)
}

export const setOrgstructureTableFilter = (value) => async (dispatch) => {
  dispatch({
    type: SET_ORGSTRUCTURE_TABLE_FILTER,
    payload: value,
  })
  return Promise.resolve(value)
}

export const setOnboardingTableFilter = (value) => async (dispatch) => {
  dispatch({
    type: SET_ONBOARDING_TABLE_FILTER,
    payload: value,
  })
  return Promise.resolve(value)
}

export const setQuizTypeFilter = (value) => async (dispatch) => {
  dispatch({
    type: SET_QUIZTYPE_FILTER,
    payload: value,
  })
  return Promise.resolve(value)
}

export const setQuizStatusFilter = (value) => async (dispatch) => {
  dispatch({
    type: SET_QUIZSTATUS_FILTER,
    payload: value,
  })
  return Promise.resolve(value)
}

export const setOkrStatusFilter = (value) => async (dispatch) => {
  dispatch({
    type: SET_OKR_STATUS_FILTER,
    payload: value,
  })
  return Promise.resolve(value)
}

export const setQuarterFilter = (value) => async (dispatch) => {
  dispatch({
    type: SET_QUARTERLY_FILTER,
    payload: value,
  })
  return Promise.resolve(value)
}

export const setPeriodFilter = (value) => async (dispatch) => {
  dispatch({
    type: SET_PERIOD_FILTER,
    payload: value,
  })
  return Promise.resolve(value)
}
