// working with env variables must be here

const DEV_VITE_API_DOMAIN = 'https://ops-frontend-dev5.dev3.nekotal.com/'
const DEV_VITE_EDITOR_WEBSOCKET_PATH = 'wss://ops-frontend-dev5.dev3.nekotal.com/ws-node'
const DEV_NODE_API_DOMAIN = 'https://ops-frontend-dev5.dev3.nekotal.com/ops-node-bff/'
const DEV_HOST = 'ops-frontend-dev5.dev3.nekotal.com'
const isDev = () => window.location.href.includes(DEV_HOST)
const DEV_PROMPT_EDITOR_API = 'https://api-gpt-mentor-editor-dev5.dev3.nekotal.com'

export const API_DOMAIN = isDev() ? DEV_VITE_API_DOMAIN : import.meta.env.VITE_API_DOMAIN
export const PUBLIC_PATH = import.meta.env.VITE_PUBLIC_PATH
export const PROMPT_EDITOR_API = isDev() ? DEV_PROMPT_EDITOR_API : import.meta.env.VITE_PROMPT_EDITOR_API
export const EDITOR_WEBSOCKET_PATH = isDev() ? DEV_VITE_EDITOR_WEBSOCKET_PATH : import.meta.env.VITE_EDITOR_WEBSOCKET_PATH
export const NODE_API_DOMAIN = isDev() ? DEV_NODE_API_DOMAIN : import.meta.env.VITE_NODE_API_DOMAIN
export const BLANK_USER_PHOTO = import.meta.env.VITE_BLANK_USER_PHOTO
export const DISABLE_FRONT_CACHE = import.meta.env.VITE_DISABLE_FRONT_CACHE
export const APP_MODE = import.meta.env.MODE
