import { ADD_MAINNUMBER, GET_MAINNUMBERS } from '../../actions/mainNumbers/actionTypes'

const initialState = {
  mainNumbers: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newMainNumbers = {}

  switch (actionType) {
    case GET_MAINNUMBERS:
      payload.forEach((item) => (newMainNumbers[item.id] = item))
      return { ...state, mainNumbers: newMainNumbers }
    case ADD_MAINNUMBER:
      state.mainNumbers[payload.id] = payload
      return { ...state, mainNumbers: { ...state.mainNumbers } }
    default:
      return state
  }
}
