import { toast } from 'react-toastify'
import { axiosConfig as axios, tokenConfig } from 'src/configs/axiosConfig'
import { LOGIN_FAIL } from '../auth/actionTypes'
import { GET_APP, UPDATE_APP } from './actionTypes'

export const fetchApp = () => async (dispatch, getState) => {
  try {
    const response = await axios.get('/api/app/', tokenConfig(getState))

    dispatch({
      type: GET_APP,
      payload: response.data,
    })
  } catch (err) {
    if (err.response.status === 403 && err.response.data && err.response.data.detail === 'Authentication credentials were not provided.') {
      dispatch({
        type: LOGIN_FAIL,
      })
    }
    console.log(err)
  }
}

export const updateApp = (data) => async (dispatch, getState) => {
  try {
    const response = await axios.put('/api/app/1/', JSON.stringify(data), tokenConfig(getState))

    dispatch({
      type: UPDATE_APP,
      payload: response.data,
    })
    toast.success('App was successfully updated')
    return response.data
  } catch (err) {
    toast.error('App was not updated')
    if (err.response.status === 403 && err.response.data && err.response.data.detail === 'Authentication credentials were not provided.') {
      dispatch({
        type: LOGIN_FAIL,
      })
    }
    console.log(err)
  }
}
