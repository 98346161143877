import { GET_CURRENCIES } from '../../actions/currencies/actionTypes'

const initialState = {
  currencies: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload

  switch (actionType) {
    case GET_CURRENCIES:
      return { ...state, currencies: payload }
    default:
      return state
  }
}
