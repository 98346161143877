import { GET_QUIZANSWER_DETAILS } from '../../actions/quizAnswerDetails/actionTypes'

const initialState = {
  quizAnswerDetails: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newQuizAnswerDetails = {}
  switch (actionType) {
    case GET_QUIZANSWER_DETAILS:
      payload.forEach((item) => (newQuizAnswerDetails[item.id] = item))
      return { ...state, quizAnswerDetails: newQuizAnswerDetails }
    default:
      return state
  }
}
