import { GET_COMPONENTS } from '../../actions/components/actionTypes'

const initialState = {
  components: [],
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const components = {}

  switch (actionType) {
    case GET_COMPONENTS:
      payload.forEach((item) => (components[item.id] = item))
      return { ...state, components: components }
    default:
      return state
  }
}
