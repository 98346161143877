import { ADD_ATTENDANCE, GET_ATTENDANCES } from '../../actions/attendances/actionTypes'

const initialState = {
  attendances: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newAttendances = {}
  switch (actionType) {
    case GET_ATTENDANCES:
      payload.forEach((item) => (newAttendances[item.id] = item))
      return { ...state, attendances: newAttendances }
    case ADD_ATTENDANCE:
      state.attendances[payload.id] = payload
      return { ...state, attendances: { ...state.attendances } }
    default:
      return state
  }
}
