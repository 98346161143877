import { ADD_QUIZ_PAGE, DELETE_QUIZ_PAGE, GET_QUIZ_PAGES, UPDATE_QUIZ_PAGE } from '../../actions/quizPages/actionTypes'

const initialState = {
  quizPages: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newQuizPages = {}
  switch (actionType) {
    case GET_QUIZ_PAGES:
      payload.forEach((item) => (newQuizPages[item.id] = item))
      return { ...state, quizPages: newQuizPages }
    case ADD_QUIZ_PAGE:
      state.quizPages[payload.id] = payload
      return { ...state, quizPages: { ...state.quizPages } }
    case DELETE_QUIZ_PAGE:
      state.quizPages[payload.id] = payload
      return { ...state, quizPages: { ...state.quizPages } }
    case UPDATE_QUIZ_PAGE:
      delete state.quizPages[payload]
      return { ...state, quizPages: { ...state.quizPages } }
    default:
      return state
  }
}
