import moment from 'moment'
import {
  SET_DATEFILTER,
  SET_MULTIROLEFILTER,
  SET_MULTIUNIT_FILTER,
  SET_OKR_STATUS_FILTER,
  SET_ONBOARDING_TABLE_FILTER,
  SET_ORGSTRUCTURE_TABLE_FILTER,
  SET_PERIOD_FILTER,
  SET_QUARTERLY_FILTER,
  SET_QUIZSTATUS_FILTER,
  SET_QUIZTYPE_FILTER,
  SET_ROLEFILTER,
  SET_UNITFILTER,
  SET_USERFILTER,
} from '../../actions/filters/actionTypes'

const initialState = {
  unitFilter: { unit: null, id: -1 },
  roleFilter: { role: null, id: -1 },
  userFilter: null,
  dateFilter: moment(),
  offerFilter: '',
  multiRoleFilter: [],
  multiUnitFilter: [],
  orgstructureTableFilter: 'okrTable',
  onboardingTableFilter: 'teamOnboardingRate',
  quizTypeFilter: 'Onboarding',
  quizStatusFilter: 'None',
  okrStatusFilter: ['active'],
  periodFilter: 'week',
  quarterFilter: `${moment().startOf('quarter').format('DD/MM/YYYY')}`,
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  switch (actionType) {
    case SET_ROLEFILTER:
      return { ...state, roleFilter: payload }
    case SET_MULTIROLEFILTER:
      return { ...state, multiRoleFilter: payload }
    case SET_UNITFILTER:
      return { ...state, unitFilter: payload }
    case SET_USERFILTER:
      return { ...state, userFilter: payload }
    case SET_DATEFILTER:
      return { ...state, dateFilter: payload }
    case SET_ORGSTRUCTURE_TABLE_FILTER:
      return { ...state, orgstructureTableFilter: payload }
    case SET_ONBOARDING_TABLE_FILTER:
      return { ...state, onboardingTableFilter: payload }
    case SET_QUIZTYPE_FILTER:
      return { ...state, quizTypeFilter: payload }
    case SET_QUIZSTATUS_FILTER:
      return { ...state, quizStatusFilter: payload }
    case SET_OKR_STATUS_FILTER:
      return { ...state, okrStatusFilter: payload }
    case SET_MULTIUNIT_FILTER:
      return { ...state, multiUnitFilter: payload }
    case SET_QUARTERLY_FILTER:
      return { ...state, quarterFilter: payload }
    case SET_PERIOD_FILTER:
      return { ...state, periodFilter: payload }
    default:
      return state
  }
}
