import { SET_PURCHASE_COUNT, SET_PURCHASE_FINANCE_COUNT } from '../../actions/journal_purchase/actionTypes'

const initialState = {
  count: {},
  countFinance: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload

  switch (actionType) {
    case SET_PURCHASE_COUNT:
      return { ...state, count: payload }
    case SET_PURCHASE_FINANCE_COUNT:
      return { ...state, countFinance: payload }
    default:
      return state
  }
}
