import { createTheme } from '@mui/material/styles'

const primary = '#363fbd'
const white = '#fff'
const black = '#000'
const danger = '#ea5455'
const success = '#28c76f'

export const theme = createTheme({
  overrides: {
    MuiChip: {
      sizeSmall: {
        height: '20px',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '12pt',
        backgroundColor: 'hsla(0,0%,91%,.9)',
        color: '#666',
        padding: '10px',
        maxWidth: '550px',
      },
    },
    MuiAutocomplete: {
      option: {
        '&[data-focus="true"]': {
          backgroundColor: 'rgb(178, 212, 255)',
        },
      },
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'Helvetica', 'Arial', 'serif'].join(','),
    subtitle1: {
      fontWeight: 700,
    },
  },
  palette: {
    primary: {
      main: primary,
    },
    danger: {
      main: danger,
    },
    success: {
      main: success,
    },
    common: {
      white,
      black,
    },
    gray: {
      main: '#626262',
    },
  },
})
