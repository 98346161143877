import {
  ADD_MINI360REVIEWS_COMMENT,
  DELETE_MINI360REVIEWS_COMMENT,
  GET_MINI360REVIEWS_COMMENTS,
  UPDATE_MINI360REVIEWS_COMMENT,
} from '../../actions/mini360ReviewsComments/actionTypes'

const initialState = {
  mini360ReviewsComments: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const { payload } = action
  const newMini360ReviewsComments = {}

  switch (actionType) {
    case GET_MINI360REVIEWS_COMMENTS:
      payload.forEach((item) => (newMini360ReviewsComments[item.id] = item))
      return { ...state, mini360ReviewsComments: newMini360ReviewsComments, comments: payload }
    case UPDATE_MINI360REVIEWS_COMMENT:
      return {
        ...state,
        mini360ReviewsComments: {
          ...state.mini360ReviewsComments,
          [payload.id]: payload,
        },
      }
    case ADD_MINI360REVIEWS_COMMENT:
      state.mini360ReviewsComments[payload.id] = payload
      return {
        ...state,
        mini360ReviewsComments: { ...state.mini360ReviewsComments },
      }
    case DELETE_MINI360REVIEWS_COMMENT:
      delete state.mini360ReviewsComments[payload]
      return {
        ...state,
        mini360ReviewsComments: { ...state.mini360ReviewsComments },
      }
    default:
      return state
  }
}
