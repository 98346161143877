import { ADD_TASK, DELETE_TASK, GET_TASK, GET_TASKS, SET_LAST_OPERATION, UPDATE_TASK } from 'src/redux/actions/tasks/actionTypes'

const initialState = {
  tasks: {},
  lastOperation: null,
}

export default (state = initialState, action) => {
  const actionType = action.type
  const { payload, shouldOverwrite } = action
  const newTasks = {}
  switch (actionType) {
    case GET_TASKS:
      payload.forEach((item) => (newTasks[item.id] = item))
      return {
        ...state,
        tasks: shouldOverwrite ? newTasks : { ...state.tasks, ...newTasks },
      }
    case GET_TASK:
      state.tasks[payload.id] = payload
      return { ...state, tasks: { ...state.tasks } }
    case ADD_TASK:
      state.tasks[payload.id] = payload
      return { ...state, tasks: { ...state.tasks } }
    case UPDATE_TASK:
      state.tasks[payload.id] = payload
      return { ...state, tasks: { ...state.tasks } }
    case DELETE_TASK:
      delete state.tasks[payload]
      return { ...state, tasks: { ...state.tasks } }
    case SET_LAST_OPERATION:
      return { ...state, lastOperation: payload }
    default:
      return state
  }
}
