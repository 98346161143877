import { DELETE_UNIT, GET_UNIT, GET_UNITS, UPDATE_UNIT } from '../../actions/units/actionTypes'

const initialState = {
  units: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newUnits = {}

  switch (actionType) {
    case GET_UNITS:
      payload.forEach((item) => (newUnits[item.unit] = item))
      return { ...state, units: newUnits }
    case GET_UNIT:
      state.units[payload.unit] = payload
      return { ...state, units: { ...state.units } }
    case UPDATE_UNIT:
      state.units[payload.unit] = payload
      return { ...state, units: { ...state.units } }
    case DELETE_UNIT:
      delete state.units[payload]
      return { ...state }
    default:
      return state
  }
}
