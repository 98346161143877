import { ADD_PNL, GET_PNLS, UPDATE_PNL } from '../../actions/pnl/actionTypes'

const initialState = {
  pnl: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newPnls = {}
  switch (actionType) {
    case GET_PNLS:
      payload.forEach((item) => (newPnls[item.id] = item))
      return { ...state, pnl: newPnls }
    case ADD_PNL:
      state.pnl[payload.id] = payload
      return { ...state, pnl: { ...state.pnl } }
    case UPDATE_PNL:
      state.pnl[payload.id] = payload
      return { ...state, pnl: { ...state.pnl } }
    default:
      return state
  }
}
