import { ADD_PROBLEM, GET_PROBLEMS } from '../../actions/problems/actionTypes'

const initialState = {
  problems: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newProblems = {}

  switch (actionType) {
    case GET_PROBLEMS:
      payload.forEach((item) => (newProblems[item.id] = item))
      return { ...state, problems: newProblems }
    case ADD_PROBLEM:
      state.problems[payload.id] = payload
      return { ...state, problems: { ...state.problems } }
    default:
      return state
  }
}
