import { ADD_TESTWEEK, GET_TESTWEEK, GET_TESTWEEKS } from '../../actions/testWeek/actionTypes'

const initialState = {
  testWeeks: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newTestWeeks = {}
  switch (actionType) {
    case GET_TESTWEEKS:
      payload.forEach((item) => (newTestWeeks[item.id] = item))
      return { ...state, testWeeks: newTestWeeks }
    case GET_TESTWEEK:
      state.testWeeks[payload.id] = payload
      return { ...state, testWeeks: { ...state.testWeeks } }
    case ADD_TESTWEEK:
      state.testWeeks[payload.id] = payload
      return { ...state, testWeeks: { ...state.testWeeks } }
    default:
      return state
  }
}
