import { GET_GRADES } from '../../actions/grades/actionTypes'

const initialState = {
  grades: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload

  switch (actionType) {
    case GET_GRADES:
      return { ...state, grades: payload }
    default:
      return state
  }
}
