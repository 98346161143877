import $ from 'jquery'
import 'jquery-contextmenu/dist/jquery.contextMenu.min.js'
import 'jquery-contextmenu/dist/jquery.contextMenu.min.css'

import { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Logo } from 'src/components/2022/logo'
import { store } from './redux/storeConfig/store'
import { App } from './App'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { APP_MODE } from 'src/env'

window.jQuery = window.$ = $

const root = (
  <Provider store={store}>
    <Suspense fallback={<Logo />}>
      <App />
    </Suspense>
  </Provider>
)

const main = () => {
  setTimeout(() => {
    import('moment-timezone').then((module) => module.default.tz.setDefault('Europe/Moscow'))
    import('moment').then((module) => module.default.updateLocale('en', { week: { dow: 1 } }))
  }, 0)

  if (APP_MODE !== 'development') {
    Sentry.init({
      dsn: 'https://6223630740e2454087f0fc67eb7d2605@o4504644400054272.ingest.sentry.io/4504677291393024',
      integrations: [new Integrations.BrowserTracing()],
    })
  }

  ReactDOM.render(root, document.getElementById('root'))
}

main()
export default root
