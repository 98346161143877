import { ADD_FEEDBACK, GET_FEEDBACK, GET_FEEDBACKS, UPDATE_FEEDBACK } from '../../actions/feedbacks/actionTypes'

const initialState = {
  feedbacks: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newFeedbacks = {}
  switch (actionType) {
    case GET_FEEDBACKS:
      payload.forEach((item) => (newFeedbacks[item.id] = item))
      return { ...state, feedbacks: newFeedbacks }
    case GET_FEEDBACK:
      state.feedbacks[payload.id] = payload
      return { ...state, feedbacks: { ...state.feedbacks } }
    case ADD_FEEDBACK:
      state.feedbacks[payload.id] = payload
      return { ...state, feedbacks: { ...state.feedbacks } }
    case UPDATE_FEEDBACK:
      state.feedbacks[payload.id] = payload
      return { ...state, feedbacks: { ...state.feedbacks } }
    default:
      return state
  }
}
