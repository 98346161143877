import { ADD_ONETOONE, GET_ONETOONES } from '../../actions/oneToOne/actionTypes'

const initialState = {
  oneToOnes: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newOneToOnes = {}

  switch (actionType) {
    case GET_ONETOONES:
      payload.forEach((item) => (newOneToOnes[item.id] = item))
      return { ...state, oneToOnes: newOneToOnes }
    case ADD_ONETOONE:
      state.oneToOnes[payload.id] = payload
      return { ...state, oneToOnes: { ...state.oneToOnes } }
    default:
      return state
  }
}
