import {
  ADD_BASE_REMUNERATION_AGREEMENT,
  DELETE_BASE_REMUNERATION_AGREEMENT,
  GET_BASE_REMUNERATION_AGREEMENT,
  GET_BASE_REMUNERATION_AGREEMENTS,
  UPDATE_BASE_REMUNERATION_AGREEMENT,
} from '../../actions/baseRemunerationAgreements/actionTypes'

const initialState = {
  baseRemunerationAgreements: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newBaseRemunerationAgreements = {}

  switch (actionType) {
    case GET_BASE_REMUNERATION_AGREEMENTS:
      payload.forEach((item) => (newBaseRemunerationAgreements[item.id] = item))
      return { ...state, baseRemunerationAgreements: newBaseRemunerationAgreements }
    case GET_BASE_REMUNERATION_AGREEMENT:
      state.baseRemunerationAgreements[payload.id] = payload
      return { ...state, baseRemunerationAgreements: { ...state.baseRemunerationAgreements } }
    case ADD_BASE_REMUNERATION_AGREEMENT:
      state.baseRemunerationAgreements[payload.id] = payload
      return { ...state, baseRemunerationAgreements: { ...state.baseRemunerationAgreements } }
    case UPDATE_BASE_REMUNERATION_AGREEMENT:
      state.baseRemunerationAgreements[payload.id] = payload
      return { ...state, baseRemunerationAgreements: { ...state.baseRemunerationAgreements } }
    case DELETE_BASE_REMUNERATION_AGREEMENT:
      delete state.baseRemunerationAgreements[payload]
      return { ...state, baseRemunerationAgreements: { ...state.baseRemunerationAgreements } }
    default:
      return state
  }
}
