import { GET_OKR_V2, GET_OKRS_V2 } from '../../actions/okrsV2/actionTypes'

const initialState = {
  okrs: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newOkrs = {}
  switch (actionType) {
    case GET_OKR_V2:
      state.okrs[payload.id] = payload
      return { ...state, okrs: { ...state.okrs } }
    case GET_OKRS_V2:
      payload.forEach((item) => (newOkrs[item.id] = item))
      return { ...state, okrs: newOkrs }
    default:
      return state
  }
}
