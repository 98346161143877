import {
  ADD_ONBOARDING_QUIZ_PIPLINE,
  DELETE_ONBOARDING_QUIZ_PIPLINE,
  GET_ONBOARDING_QUIZ_PIPLINE_RESULTS,
  GET_ONBOARDING_QUIZ_PIPLINES,
  UPDATE_ONBOARDING_QUIZ_PIPLINE,
} from '../../actions/onboardingQuizPiplines/actionTypes'

const initialState = {
  onboardingQuizPiplines: {},
  onboardingQuizPiplineResults: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newOnboardingQuizPiplines = {}
  const newOnboardingQuizPiplineResults = {}

  switch (actionType) {
    case GET_ONBOARDING_QUIZ_PIPLINES:
      payload.forEach((item) => (newOnboardingQuizPiplines[item.id] = item))
      return { ...state, onboardingQuizPiplines: newOnboardingQuizPiplines }
    case GET_ONBOARDING_QUIZ_PIPLINE_RESULTS:
      payload.forEach((item) => (newOnboardingQuizPiplineResults[item.id] = item))
      return {
        ...state,
        onboardingQuizPiplineResults: newOnboardingQuizPiplineResults,
      }
    case ADD_ONBOARDING_QUIZ_PIPLINE:
      state.onboardingQuizPiplines[payload.id] = payload
      return {
        ...state,
        onboardingQuizPiplines: { ...state.onboardingQuizPiplines },
      }
    case UPDATE_ONBOARDING_QUIZ_PIPLINE:
      state.onboardingQuizPiplines[payload.id] = payload
      return {
        ...state,
        onboardingQuizPiplines: { ...state.onboardingQuizPiplines },
      }
    case DELETE_ONBOARDING_QUIZ_PIPLINE:
      delete state.onboardingQuizPiplines[payload]
      return {
        ...state,
        onboardingQuizPiplines: { ...state.onboardingQuizPiplines },
      }
    default:
      return state
  }
}
