import { applyMiddleware, compose, createStore } from 'redux'
import createDebounce from 'redux-debounced'
import thunk from 'redux-thunk'
import rootReducer from '../reducers/rootReducer'

const middlewares = [thunk, createDebounce()]

// const persistedState = loadFromLocalStorage()
const persistedState = undefined

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(...middlewares)))

export { store }
