import { ADD_STOCKOPTION, DELETE_STOCKOPTION, GET_STOCKOPTION, GET_STOCKOPTIONS, UPDATE_STOCKOPTION } from '../../actions/stockOptions/actionTypes'

const initialState = {
  stockOptions: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newStockOptions = {}

  switch (actionType) {
    case GET_STOCKOPTION:
      state.stockOptions[payload.id] = payload
      return { ...state, stockOptions: { ...state.stockOptions } }
    case GET_STOCKOPTIONS:
      payload.forEach((item) => (newStockOptions[item.id] = item))
      return { ...state, stockOptions: newStockOptions }
    case ADD_STOCKOPTION:
      state.stockOptions[payload.id] = payload
      return { ...state, stockOptions: { ...state.stockOptions } }
    case UPDATE_STOCKOPTION:
      state.stockOptions[payload.id] = payload
      return { ...state, stockOptions: { ...state.stockOptions } }
    case DELETE_STOCKOPTION:
      delete state.stockOptions[payload]
      return { ...state, stockOptions: { ...state.stockOptions } }
    default:
      return state
  }
}
