import { ADD_TASKTEST, GET_TASKTEST, GET_TASKTESTS } from '../../actions/taskTests/actionTypes'

const initialState = {
  taskTests: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newTaskTests = {}
  switch (actionType) {
    case GET_TASKTESTS:
      payload.forEach((item) => (newTaskTests[item.id] = item))
      return { ...state, taskTests: newTaskTests }
    case GET_TASKTEST:
      state.taskTests[payload.id] = payload
      return { ...state, taskTests: { ...state.taskTests } }
    case ADD_TASKTEST:
      payload.forEach((item) => (newTaskTests[item.id] = item))
      return { ...state, taskTests: { ...state.taskTests, ...newTaskTests } }
    default:
      return state
  }
}
