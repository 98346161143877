import { useEffect, useMemo, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { reducers } from 'src/redux/reducers/rootReducer'

export const mergeRecursively = (origin, delta) => {
  const merged = { ...origin }
  Object.entries(delta).forEach(([key, value]) => {
    if ('object' == typeof value && value != null && !Array.isArray(value)) {
      merged[key] = mergeRecursively(merged[key], value)
    } else {
      merged[key] = value
    }
  })
  return merged
}

reducers.register('dbV2', (state = {}, action) => {
  const newState = { ...state }
  const { model, id, data } = action
  if ('dbV2.insert' == action.type) {
    if (!(model in newState)) {
      newState[model] = {}
    }
    newState[model][id] = { ...data }
    return newState
  }
  if ('dbV2.update' == action.type) {
    newState[model][id] = mergeRecursively(newState[model][id], data)
    return newState
  }
  if ('dbV2.replaceKeys' == action.type) {
    newState[model][id] = { ...newState[model][id], ...data }
    return newState
  }
  if ('dbV2.clearField' == action.type) {
    newState[model][id].timestamp = performance.now()
    delete newState[model][id][action.field]
    return newState
  }
  return newState
})

export const useDBObjectObserverSelector = (model, id, stateGetter) => {
  const objectData = useSelector((state) => {
    const currentDbObject = state.dbV2?.[model]?.[id]
    if (stateGetter) {
      return stateGetter(currentDbObject)
    }
    return currentDbObject
  })
  return objectData
}

export const useDBObjectObserver = (model, id, defObj, stateGetter) => {
  const objectData = useSelector((state) => {
    const currentDbObject = state.dbV2?.[model]?.[id] || defObj || {}
    if (stateGetter) {
      return stateGetter(currentDbObject)
    }
    return currentDbObject
  })
  const dispatch = useDispatch()

  useEffect(() => {
    if ('object' == typeof defObj) {
      dispatch({
        type: 'dbV2.insert',
        data: defObj,
        model,
        id,
      })
    }
  }, [])

  return [
    objectData,
    {
      insert: (data) =>
        dispatch({
          type: 'dbV2.insert',
          model,
          id,
          data,
        }),
      update: (data) =>
        dispatch({
          type: 'dbV2.update',
          model,
          id,
          data,
        }),
      replaceKeys: (data) =>
        dispatch({
          type: 'dbV2.replaceKeys',
          model,
          id,
          data,
        }),
      delete: (newData) => console.log('delete', newData),
      clearField: (field) =>
        dispatch({
          type: 'dbV2.clearField',
          model,
          id,
          field,
        }),
    },
  ]
}
