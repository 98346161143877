import { ADD_FUNCTION, DELETE_FUNCTION, GET_FUNCTION, GET_FUNCTIONS, SET_LAST_OPERATION, UPDATE_FUNCTION } from '../../actions/functions/actionTypes'

const initialState = {
  functions: {},
  lastOperation: null,
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newFunctions = {}
  switch (actionType) {
    case GET_FUNCTIONS:
      payload.forEach((item) => (newFunctions[item.id] = item))
      return { ...state, functions: newFunctions }
    case GET_FUNCTION:
      state.functions[payload.id] = payload
      return { ...state, functions: { ...state.functions } }
    case ADD_FUNCTION:
      state.functions[payload.id] = payload
      return { ...state, functions: { ...state.functions } }
    case UPDATE_FUNCTION:
      state.functions[payload.id] = payload
      return { ...state, functions: { ...state.functions } }
    case DELETE_FUNCTION:
      delete state.functions[payload]
      return { ...state, functions: { ...state.functions } }
    case SET_LAST_OPERATION:
      return { ...state, lastOperation: payload }
    default:
      return state
  }
}
