import { ADD_EXITINTERVIEW, GET_EXITINTERVIEWS } from '../../actions/exitInterviews/actionTypes'

const initialState = {
  exitInterviews: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newExitInterviews = {}

  switch (actionType) {
    case GET_EXITINTERVIEWS:
      payload.forEach((item) => (newExitInterviews[item.id] = item))
      return { ...state, exitInterviews: newExitInterviews }
    case ADD_EXITINTERVIEW:
      state.exitInterviews[payload.id] = payload
      return { ...state, exitInterviews: { ...state.exitInterviews } }
    default:
      return state
  }
}
