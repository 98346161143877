import { ADD_ROLE, DELETE_ROLE, GET_ROLE, GET_ROLES, UPDATE_ROLE } from '../../actions/roles/actionTypes'

const initialState = {
  roles: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const { payload, shouldOverwrite } = action
  const newRoles = {}
  switch (actionType) {
    case GET_ROLES:
      payload.forEach((item) => (newRoles[item.id] = item))
      return {
        ...state,
        roles: shouldOverwrite ? newRoles : { ...state.roles, ...newRoles },
      }
    case GET_ROLE:
      state.roles[payload.id] = payload
      return { ...state, roles: { ...state.roles } }
    case UPDATE_ROLE:
      state.roles[payload.id] = payload
      return { ...state, roles: { ...state.roles } }
    case ADD_ROLE:
      state.roles[payload.id] = payload
      return { ...state, roles: { ...state.roles } }
    case DELETE_ROLE:
      delete state.roles[payload]
      return { ...state, roles: { ...state.roles } }
    default:
      return state
  }
}
