import { ADD_OKRVALUE, DELETE_OKRVALUE, GET_OKRVALUES, UPDATE_OKRVALUE } from '../../actions/okrValues/actionTypes'

const initialState = {
  okrValues: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newOkrValues = {}

  switch (actionType) {
    case GET_OKRVALUES:
      payload.forEach((item) => (newOkrValues[item.id] = item))
      return { ...state, okrValues: newOkrValues }
    case ADD_OKRVALUE:
      state.okrValues[payload.id] = payload
      return { ...state, okrValues: { ...state.okrValues } }
    case UPDATE_OKRVALUE:
      state.okrValues[payload.id] = payload
      return { ...state, okrValues: { ...state.okrValues } }
    case DELETE_OKRVALUE:
      delete state.okrValues[payload]
      return { ...state, okrValues: { ...state.okrValues } }
    default:
      return state
  }
}
