import { combineReducers } from 'redux'
// import calenderReducer from './calendar/'
// import chatReducer from './chat/'
// import dataList from './data-list/'
// import emailReducer from './email/'
// import fcmDevicesReducer from './fcmDevices'
// import notificationReducer from './notifications'
// import professionsReducer from './professions'
// import scriptsReducer from './scripts'
import appReducer from './app'
import attendanceReducer from './attendances'
import auth from './auth/'
import baseRemunerationAgreementsReducer from './baseRemunerationAgreements'
import checklistReducer from './checklists'
import componentsReducer from './components'
import currenciesReducer from './currencies'
import customizer from './customizer/'
import exitInterviewsReducer from './exitInterviews'
import featuresReducer from './features'
import feedbacksReducer from './feedbacks'
import filtersReducer from './filters/'
import functionsReducer from './functions/'
import gradesReducer from './grades'
import hrTalksReducer from './hrTalks'
import kpiReducer from './kpi'
import mainNumbersReducer from './mainNumbers'
import mini360ReviewsCommentsRedcer from './mini360ReviewsComments'
import mini360ReviewsReducer from './mini360Reviews'
import navbar from './navbar/Index'
import offerListReducer from './offerList'
import okrsReducer from './okrs'
import okrsV2Reducer from './okrsV2'
import okrValuesReducer from './okrValues'
import onboardingArticlesReducer from './onboardingArticles'
import onboardingQuizPiplinesReducer from './onboardingQuizPiplines'
import oneToOneReducer from './oneToOne'
import pnlReducer from './pnl'
import problemsReducer from './problems'
import profilePageReducer from './profilePage'
import purchasesReducer from './purchase'
import quizAnswersDetailsReducer from './quizAnswerDetails'
import quizAnswersReducer from './quizAnswers'
import quizesReducer from './quizes'
import quizHealthReducer from './quizHealth'
import quizPagesReder from './quizPages'
import taskTestReducer from './taskTests'
import testWeekReducer from './testWeek'
import quizQuestionsReducer from './quizQuestions'
import rolesReducer from './roles/'
import stockOptionsReducer from './stockOptions'
import submissionReportReducer from './submissionReport'
import tasksReducer from './tasks/'
import unitsReducer from './units/'
import serviceAgreementReducer from './serviceAgreement'

const oldReducer = {
  // calendar: calenderReducer,
  // emailApp: emailReducer,
  //   todoApp: todoReducer,
  // chatApp: chatReducer,
  customizer: customizer,
  navbar: navbar,
  // dataList: dataList,
  //  OUR OWN STATE
  auth: auth,
  functions: functionsReducer,
  roles: rolesReducer,
  tasks: tasksReducer,
  units: unitsReducer,
  // professions: professionsReducer,
  app: appReducer,
  features: featuresReducer,
  components: componentsReducer,
  filters: filtersReducer,
  onboardingArticles: onboardingArticlesReducer,
  mini360Reviews: mini360ReviewsReducer,
  checklists: checklistReducer,
  oneToOnes: oneToOneReducer,
  attendances: attendanceReducer,
  mini360ReviewsComments: mini360ReviewsCommentsRedcer,
  taskTests: taskTestReducer,
  testWeeks: testWeekReducer,
  // notifications: notificationReducer,
  // fcmDevices: fcmDevicesReducer,
  exitInterviews: exitInterviewsReducer,
  // scripts: scriptsReducer,
  problems: problemsReducer,
  offerList: offerListReducer,
  serviceAgreement: serviceAgreementReducer,
  grades: gradesReducer,
  feedbacks: feedbacksReducer,
  quizHealth: quizHealthReducer,
  quizes: quizesReducer,
  quizAnswers: quizAnswersReducer,
  quizAnswersDetails: quizAnswersDetailsReducer,
  pnl: pnlReducer,
  kpi: kpiReducer,
  currencies: currenciesReducer,
  purchases: purchasesReducer,
  onboardingQuizPiplines: onboardingQuizPiplinesReducer,
  hrTalks: hrTalksReducer,
  mainNumbers: mainNumbersReducer,
  okrs: okrsReducer,
  okrsV2: okrsV2Reducer,
  okrValues: okrValuesReducer,
  baseRemunerationAgreements: baseRemunerationAgreementsReducer,
  stockOptions: stockOptionsReducer,
  quizPages: quizPagesReder,
  quizQuestions: quizQuestionsReducer,
  submissionReport: submissionReportReducer,
  profilePage: profilePageReducer,
}

const reducers = {
  combine: () => combineReducers(oldReducer),
  register: (key, reducer) => {
    oldReducer[key] = reducer
  },
  add: (sources) => Object.assign(oldReducer, sources),
  prune: (key) => delete oldReducer[key],
}
const rootReducerv2 = (state, action) => reducers.combine()(state, action)

export { reducers }
export default rootReducerv2
