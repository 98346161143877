import { ADD_OFFER_LIST, DELETE_OFFER_LIST, GET_OFFER_LIST, UPDATE_OFFER_LIST } from '../../actions/offerList/actionTypes'

const initialState = {
  offerList: {},
}

export default (state = initialState, action) => {
  const actionType = action.type
  const payload = action.payload
  const newOffers = {}

  switch (actionType) {
    case GET_OFFER_LIST:
      payload.forEach((item) => (newOffers[item.id] = item))
      return { ...state, offerList: newOffers }
    case ADD_OFFER_LIST:
      state.offerList[payload.id] = payload
      return { ...state, offerList: { ...state.offerList } }
    case UPDATE_OFFER_LIST:
      state.offerList[payload.id] = payload
      return { ...state, offerList: { ...state.offerList } }
    case DELETE_OFFER_LIST:
      delete state.offerList[payload]
      return { ...state, offerList: { ...state.offerList } }
    default:
      return state
  }
}
